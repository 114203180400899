<template>
    <div class="inquiry">
        <div class="nl-container">
            <p class="inquiry__text1">{{ $t('fbn.text') }}</p>
            <p class="inquiry__text2">{{ $t('fbn.text2') }}</p>

            <a :href='`https://lecos.co.kr/${this.$route.params.lang}`' target="_blank" class="inquiry__btn nl-btn nl-btn-size--default nl-has-bg--sky-02 primary">
                <span>{{ $t('fbn.text3') }}</span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Inquiry',

    mounted() {
        //
    },

    methods: {
        // sendMail() {
        //     window.open('mailto:info@lecos.co.kr');
        // }
    }
};
</script>
