<template>
    <div class="footer">
        <div class="page-top">
            <button class="page-top__button show" @click="movePageTop($event)">
                <object type="image/svg+xml" :data="require(`@/assets/svg/icon-top.svg`)" class="page-top__svg">현재 브라우저는 iframe을 지원하지 않습니다.</object>
            </button>
        </div>
            
        <div class="nl-container">
            <ul class="fmenu">
                <li class="fmenu__item"><router-link :to="`/${this.$route.params.lang}/privacy`" class="fmenu__link">{{ $t('copyr.fmenu') }}</router-link></li>
            </ul>

            <div class="footer__info">
                <span class="footer__item">{{ $t('copyr.text') }}</span>
                <span class="footer__item"><span class="ftitle ftitle--notbul number">{{ $t('copyr.text2') }}</span> {{ $t('copyr.text21') }}</span>
                
                <div>
                    <span class="footer__item"><span class="ftitle ftitle--notbul">{{ $t('copyr.text3') }}</span> {{ $t('copyr.text31') }}</span>
                    <span class="footer__item"><span class="ftitle ftitle--none">{{ $t('copyr.text4') }}</span> {{ $t('copyr.text41') }}</span>
                    <span class="footer__item"><span class="ftitle ftitle--none">{{ $t('copyr.text5') }}</span> {{ $t('copyr.text51') }}</span>
                </div>
            </div>

            <div class="footer__copyr">
                {{ $t('copyr.text6') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',

    mounted() {
        this.scrollTop();
        window.addEventListener('scroll', this.handleScroll);
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            this.scrollTop();
        },

        scrollTop() {
            var footerHei = document.querySelector('.footer').offsetHeight;
            var topBtnElem = document.querySelector('.page-top');
            var body = document.body;
            var html = document.documentElement;

            var sT = window.scrollY || document.documentElement.scrollTop;
            var val = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight ) - window.innerHeight - footerHei;
    
            if (sT >= val) {
                topBtnElem.classList.add('on');
            } else {
                topBtnElem.classList.remove('on');
            }
        },

        movePageTop(e) {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
};
</script>
