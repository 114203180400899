<template>
    <!-- location-page --> 
    <div class="location-page">
        <top-visual-company></top-visual-company>

        <sub-menu title="company"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- location-container -->
            <div class="location-container">
                <h2 class="content-title center">{{ $t('company.location.pageTitle') }}</h2>

                <div class="address">
                    <div class="address__box">
                        <div class="address__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/icon-location.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <p class="address__text-1">{{ $t('company.location.text') }}</p>
                        <p class="address__text-2">{{ $t('company.location.text2') }}</p>
                    </div>
                </div>
            </div>
            <!-- // location-container -->
        </div>
        <!-- // nl-content -->

        <!-- map --> 
        <div class="map">
            <div class="map__img"></div>
        </div>
        <!-- // map -->
    </div>
    <!-- // location-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-location.png';
import TopVisualCompany from '../components/TopVisualCompany.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'LocationPage',
    components: {
        TopVisualCompany,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.company.location.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.company.location.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.company.location.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.company.location.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>