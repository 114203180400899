<template>
    <div class="modal-sitemap" v-if="status">
        <!-- m-sitemap -->
        <div
            v-for="(item, i) in sitemapItems" 
            :key="i"
            class="m-sitemap"
        >
            <div class="m-sitemap__title">{{ item.name }}</div>

            <ul class="m-sitemap__list">
                <li 
                    v-for="(item, i) in item.children"
                    :key="i"
                    class="m-sitemap__item"
                    @click="closeModalSitemap"
                >
                    <router-link :to="item.url" class="m-sitemap__link">
                        <span class="m-sitemap__link-content">{{ item.name }}</span>
                    </router-link>
                </li>
            </ul>
        </div>
        <!-- // m-sitemap -->

        <button class="modal-sitemap__close" @click="closeModalSitemap">
            <svg 
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                class="modal-sitemap__close-icon">
                <path fill-rule="evenodd"
                      d="M24.776,21.780 L22.603,23.870 L12.826,14.463 L3.48,23.870 L0.876,21.780 L10.653,12.373 L0.876,2.966 L3.48,0.876 L12.826,10.282 L22.603,0.876 L24.776,2.966 L14.999,12.373 L24.776,21.780 Z"/>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    name: 'ModalSitemap',

    props : [
        'status'
    ],

    data: function() {
        return {
            //
        };
    },

    computed: {
        sitemapItems() {
            return [{
                name: this.$t('menu.company.index'),
                children: [
                    {
                        url: `/${this.$route.params.lang}/company/about`,
                        name: this.$t('menu.company.sub.about')
                    },
                    {
                        url: `/${this.$route.params.lang}/company/history`,
                        name: this.$t('menu.company.sub.history')
                    },
                    {
                        url: `/${this.$route.params.lang}/company/group`,
                        name: this.$t('menu.company.sub.group')
                    },
                    {
                        url: `/${this.$route.params.lang}/company/location`,
                        name: this.$t('menu.company.sub.location')
                    }
                ]
            },
            {
                url: `/${this.$route.params.lang}/business`,
                name: this.$t('menu.business.index'),
                children: [
                    {
                        url: `/${this.$route.params.lang}/business/openbadge`,
                        name: this.$t('menu.business.sub.openbadge')
                    },
                    {
                        url: `/${this.$route.params.lang}/business/multiverse`,
                        name: this.$t('menu.business.sub.multiverse')
                    },
                    {
                        url: `/${this.$route.params.lang}/business/netlive`,
                        name: this.$t('menu.business.sub.netlive')
                    }
                ]
            },
            {
                url: `/${this.$route.params.lang}/news`,
                name: this.$t('menu.news.index'),
                children: [
                    {
                        url: `/${this.$route.params.lang}/news/press`,
                        name: this.$t('menu.news.sub.press')
                    },
                    {
                        url: `/${this.$route.params.lang}/news/list`,
                        name: this.$t('menu.news.sub.news')
                    }
                ]
            },
            {
                url: `/${this.$route.params.lang}/contact`,
                name: this.$t('menu.contact.index'),
                children: [
                    {
                        url: `/${this.$route.params.lang}/contact/recruit`,
                        name: this.$t('menu.contact.sub.recruit')
                    },
                    {
                        url: `/${this.$route.params.lang}/contact/inquery`,
                        name: this.$t('menu.contact.sub.inquery')
                    }
                ]
            },
            ];
        }
    },

    methods: {
        closeModalSitemap() {
            this.$emit('closeModalSitemap');
        }
    }
};
</script>
