<template>
    <!-- recruit-page --> 
    <div class="recruit-page">
        <top-visual-contact></top-visual-contact>

        <sub-menu title="contact"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- recruit-container -->
            <div class="recruit-container">
                <h2 class="content-title center">인재채용</h2>

                <p class="recruit-title">
                    네트러닝 그룹은 <strong class="impo">“미래를 위한 교육을 만든다”</strong>는 이념을 함께 실현하고<br /> 
                    회사를 이끌어 갈 수 있는 여러분들의 응모를 기다리고 있습니다.
                </p>

                <!-- recruit-box -->
                <div class="recruit-box">
                    <h2 class="content-title center">모집분야</h2>

                    <!-- recruit-detail-container -->
                    <div class="recruit-detail-container">
                        <!-- recruit-field -->
                        <div class="recruit-field">
                            <div class="recruit-field__col">
                                <strong class="recruit-field__title">[경력] 웹 개발자</strong>
                                <span class="recruit-field__des">(개발 부분)</span>
                            </div>

                            <div class="recruit-field__button" v-if="false">
                                <a href="https://www.jobkorea.co.kr/Recruit/GI_Read/38492286?Oem_Code=C1&logpath=1&stext=%EB%84%A4%ED%8A%B8%EB%9F%AC%EB%8B%9D&listno=2" target="_blank">
                                    잡코리아 지원하기
                                    <object type="image/svg+xml" :data="require(`@/assets/svg/icon-arr.svg`)" class="arr">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                                </a>
                            </div>
                        </div>
                        <!-- // recruit-field -->

                        <!-- recruit-detail -->
                        <div class="recruit-detail">
                            <table class="recruit-detail__table">
                                <colgroup>
                                    <col class="recruit-detail__col" />
                                    <col />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th scope="row" class="recruit-detail__title">담당업무</th>
                                        <td class="recruit-detail__text">ASP.NET과 Vue로 개발된 자사 e-learning 시스템의 신규 개발 업무, LMS 개발, RESTful API 개발</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="recruit-detail__title">자격요건</th>
                                        <td class="recruit-detail__text">대졸, 초대졸 이상 / 경력 : 3년 ~ 10년 / 성별 : 무관 / ASP.NET 또는 JSP(JAVA) 중 한가지 이상 경험이 있을 것</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="recruit-detail__title">우대조건</th>
                                        <td class="recruit-detail__text">
                                            <ul class="recruit-list">
                                                <li class="recruit-list__item">- 해당직무 근무경험이 있으신 분</li>
                                                <li class="recruit-list__item">- Vue, Angular, React 중 한가지 이상 경험이 있는 분</li>
                                                <li class="recruit-list__item">- 대용량 데이터베이스 처리가 가능한 분</li>
                                                <li class="recruit-list__item">- DB설계, T-SQL개발 가능하신 분</li>
                                                <li class="recruit-list__item">- 전산학/컴퓨터공학, 응용소프트웨어공학 전공자</li>
                                                <li class="recruit-list__item">- 영어가능자, 일본어가능자(외국어 : JLPT, JPT, NPT)</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- // recruit-detail -->
                    </div>
                    <!-- // recruit-detail-container -->

                    <!-- recruit-detail-container -->
                    <div class="recruit-detail-container add-field">
                        <!-- recruit-field -->
                        <div class="recruit-field">
                            <div class="recruit-field__col">
                                <strong class="recruit-field__title">[경력] 웹퍼블리셔</strong>
                                <span class="recruit-field__des">(개발 부분)</span>
                            </div>

                            <div class="recruit-field__button" v-if="false">
                                <a href="https://www.jobkorea.co.kr/Recruit/GI_Read/38492442?Oem_Code=C1&logpath=1&stext=%EB%84%A4%ED%8A%B8%EB%9F%AC%EB%8B%9D&listno=1" target="_blank">
                                    잡코리아 지원하기
                                    <object type="image/svg+xml" :data="require(`@/assets/svg/icon-arr.svg`)" class="arr">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                                </a>
                            </div>
                        </div>
                        <!-- // recruit-field -->

                        <!-- recruit-detail -->
                        <div class="recruit-detail">
                            <table class="recruit-detail__table">
                                <colgroup>
                                    <col class="recruit-detail__col" />
                                    <col />
                                </colgroup>

                                <tbody>
                                    <tr>
                                        <th scope="row" class="recruit-detail__title">담당업무</th>
                                        <td class="recruit-detail__text">자사 e-learning 시스템의 웹 퍼블리싱 / 자사 홈페이지 퍼블리싱 작업</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="recruit-detail__title">자격요건</th>
                                        <td class="recruit-detail__text">대졸, 초대졸 이상 / 경력 : 7년 이상 / 성별 : 무관 / HTML, CSS, SCSS, JavaScript, jQuery 사용 가능하신 분</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" class="recruit-detail__title">우대조건</th>
                                        <td class="recruit-detail__text">
                                            <ul class="recruit-list">
                                                <li class="recruit-list__item">- 일본어 가능하신 분</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- // recruit-detail -->
                    </div>
                    <!-- // recruit-detail-container -->
                </div>
                <!-- // recruit-box -->

                <!-- recruit-box -->
                <div class="recruit-box">
                    <h2 class="content-title center">인재상</h2>

                    <ul class="talent-list">
                        <li class="talent-list__item">
                            <div class="talent-list__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/talent-icon-1.svg`)" class="icon icon-1"></object></div>
                            <p class="talent-list__text"><strong class="imp">「IT × 교육」</strong>의 솔루션과 함께 <strong class="imp">미래를 개척해 나갈 인재</strong></p>
                        </li>
                        <li class="talent-list__item">
                            <div class="talent-list__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/talent-icon-2.svg`)" class="icon icon-2"></object></div>
                            <p class="talent-list__text">변화의 시대 속에서 다양한 요구에 대응할 수 있는 <strong class="imp">능동적인 인재</strong></p>
                        </li>
                        <li class="talent-list__item">
                            <div class="talent-list__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/talent-icon-3.svg`)" class="icon icon-3"></object></div>
                            <p class="talent-list__text"><strong class="imp">퍼스널 브랜딩</strong>을 통해 자신의 꿈을 실현하고 조직의 발전에 기여할 수 있는 인재</p>
                        </li>
                        <li class="talent-list__item">
                            <div class="talent-list__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/talent-icon-4.svg`)" class="icon icon-4"></object></div>
                            <p class="talent-list__text">세상을 향한 <strong class="imp">교육의 새로운 가치</strong>를 함께 실현할 수 있는 인재</p>
                        </li>
                        <li class="talent-list__item">
                            <div class="talent-list__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/talent-icon-5.svg`)" class="icon icon-5"></object></div>
                            <p class="talent-list__text">기존의 틀에 얽매이지 않는 <strong class="imp">참신한 발상과 행동력</strong>을 지닌 인재</p>
                        </li>
                        <li class="talent-list__item">
                            <div class="talent-list__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/talent-icon-6.svg`)" class="icon icon-6"></object></div>
                            <p class="talent-list__text">새로운 사업 개척에 <strong class="imp">도전하고자 하는 열정</strong>을 가지고 있는 인재</p>
                        </li>
                    </ul>
                </div>
                <!-- // recruit-box -->

                <!-- recruit-box -->
                <div class="recruit-box">
                    <h2 class="content-title center">복리후생</h2>

                    <div class="benefits">
                        <ul class="benefits__ul">
                            <li class="benefits__item">
                                <div class="benefits__icon-group">
                                    <div class="benefits__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/benefits-icon-1.svg`)" class="icon icon-1"></object></div>
                                    <div class="benefits__title">급여 제도</div>
                                </div>
                                
                                <div class="benefits__text-group">
                                    <ul>
                                        <li class="benefits__item-text">4대 보험, 퇴직금</li>
                                        <li class="benefits__item-text">우수사원 표창, 잔업수당</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="benefits__item">
                                <div class="benefits__icon-group">
                                    <div class="benefits__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/benefits-icon-2.svg`)" class="icon icon-2"></object></div>
                                    <div class="benefits__title">교육 지원</div>
                                </div>

                                <div class="benefits__text-group">
                                    <ul>
                                        <li class="benefits__item-text">교육비, 도서비, 자격증 취득 지원</li>
                                        <li class="benefits__item-text">본사 연수, 일본 본사 근무 기회(신청자)</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="benefits__item">
                                <div class="benefits__icon-group">
                                    <div class="benefits__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/benefits-icon-3.svg`)" class="icon icon-3"></object></div>
                                    <div class="benefits__title">회사 문화</div>
                                </div>

                                <div class="benefits__text-group">
                                    <ul>
                                        <li class="benefits__item-text">회식 강요 안함</li>
                                        <li class="benefits__item-text">자율 복장</li>
                                        <li class="benefits__item-text">야근 강요 안함</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="benefits__item">
                                <div class="benefits__icon-group">
                                    <div class="benefits__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/benefits-icon-4.svg`)" class="icon icon-4"></object></div>
                                    <div class="benefits__title">근무 환경</div>
                                </div>

                                <div class="benefits__text-group">
                                    <ul>
                                        <li class="benefits__item-text">지하철역(도보) 3분 거리</li>
                                        <li class="benefits__item-text">듀얼 모니터 지급</li>
                                        <li class="benefits__item-text">재택근무용 노트북 지급</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="benefits__item">
                                <div class="benefits__icon-group">
                                    <div class="benefits__icon"><object type="image/svg+xml" :data="require(`@/assets/svg/benefits-icon-5.svg`)" class="icon icon-5"></object></div>
                                    <div class="benefits__title">휴가 제도</div>
                                </div>

                                <div class="benefits__text-group">
                                    <ul>
                                        <li class="benefits__item-text">주 5일 근무, 연차, 정기휴가(하계 3일)</li>
                                        <li class="benefits__item-text">경조 휴가, 생일 휴가, 반차 휴가</li>
                                        <li class="benefits__item-text">시간 단위(1시간)휴가, 재택 근무 및 순환 근무(탄력운영)</li>
                                        <li class="benefits__item-text">Refresh 휴가, 포상휴가, 출산 전, 후 휴가,  육아 휴직, 보건 휴가, 노동절 휴무</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- // recruit-box -->
            </div>
            <!-- // recruit-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // recruit-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-recruit.png';
import TopVisualContact from '../components/TopVisualContact.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'RecruitPage',
    components: {
        TopVisualContact,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.contact.recruit.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.contact.recruit.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.contact.recruit.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.contact.recruit.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>