<template>
    <div class="page-down">
        <object type="image/svg+xml" :data="require(`@/assets/svg/icon-down.svg`)" class="down__svg">현재 브라우저는 iframe을 지원하지 않습니다.</object>
    </div>
</template>

<script>
export default {
    name: 'PageDown',

    methods: {
        //
    }
};
</script>
