<template>
    <!-- press-page --> 
    <div class="press-page">
        <top-visual-news></top-visual-news>

        <sub-menu title="news"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- news-list-container -->
            <div class="news-list-container">
                <h2 class="content-title center">
                    언론에 보도된 네트러닝 홀딩스의 소식을 확인하세요.
                </h2>

                <div class="board-list-container">
                    <ul class="board-list board-type-2">
                        <li v-for="item in getPress" :key="item.id" class="board-list__item">
                            <div class="newsroom">
                                <object type="image/svg+xml" :data="require(`@/assets/svg/icon-newsroom.svg`)" class="icon"></object>
                                <div class="text">{{ item.newsroom }}</div>
                            </div>

                            <div class="board-list__title">
                                <a :href="item.link" target="_blank" class="board-list__title">{{ item.title }}</a>
                            </div>
                            
                            <div class="date">{{ item.date }}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- // news-list-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // press-page -->
</template>

<script>
import { mapGetters } from 'vuex';
import ogImage from '../assets/img/og-sns-news.png';
import TopVisualNews from '../components/TopVisualNews.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'PressListPage',
    components: {
        TopVisualNews,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.news.press.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.news.press.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.news.press.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.news.press.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    computed: {
        ...mapGetters('news', ['getPress']),
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>