// import axios from 'axios';
import api from '@/api';
import {
//
} from './mutation-types';

export default {
    // eslint-disable-next-line no-unused-vars
    sendEmail ({ commit }, inquery) {
        console.log('action! inquery', inquery);        
        // return api.post('/inquery/sendEmail', inquery);
        // return api.post('/v1/ContactUsByEmail', inquery);

        // return api.post('/inquery/sendEmail', inquery, {
        //     headers: {
        //         'Accept-Language': inquery.lang
        //     },
        // });

        // https://test.lecos.co.kr/api/v1/ContactUsByEmail
        return api.post('/api/v1/ContactUsByEmail', inquery, {
            headers: {
                'Accept-Language': inquery.lang
            },
        });

        // return api.put('/api/v1.0/learningSpace/productCancellation', null, {
        //     headers: {
        //         product_id: payload.productId,
        //         product_status: payload.productStatus,
        //     },
        // });
    },
};
