<template>
    <!-- openbadge-page --> 
    <div class="openbadge-page">
        <top-visual-business></top-visual-business>

        <sub-menu title="business"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- openbadge-container -->
            <div class="openbadge-container">
                <h2 class="content-title center">
                    <span class="content-title__desc">{{ $t('business.openbadge.pageTitle') }}</span>
                    {{ $t('business.openbadge.pageTitleDes') }}
                </h2>

                <!-- desc-box-container -->
                <div class="desc-box-container">
                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.openbadge.pra.title') }}</div>

                            <div class="desc-box__text">{{ $t('business.openbadge.pra.text') }}</div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.openbadge.pra.image')}`)" class="pos-1" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.openbadge.pra2.title') }}</div>

                            <div class="desc-box__text">{{ $t('business.openbadge.pra2.text') }}</div>

                            <a href="https://site.imsglobal.org/certifications/netlearning-holdings-inc/multiverse#cert_pane_nid_195046" target="_blank" class="btn-sample nl-btn nl-btn-size--default nl-has-bg--sky-02 primary"><span>{{ $t('business.openbadge.pra2.btn') }}</span></a>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.openbadge.pra2.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.openbadge.pra3.title') }}</div>

                            <div class="desc-box__text" v-html="$t('business.openbadge.pra3.text')">
                            </div>

                            <div class="desc-box__text-2" v-html="$t('business.openbadge.pra3.text2')">
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.openbadge.pra3.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.openbadge.pra4.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.openbadge.pra4.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.openbadge.pra4.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.openbadge.pra5.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.openbadge.pra5.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.openbadge.pra5.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.openbadge.pra6.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.openbadge.pra6.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.openbadge.pra6.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.openbadge.pra7.title') }}</div>
                            
                            <div class="desc-box__text">
                                {{ $t('business.openbadge.pra7.text') }}
                            </div>

                            <a :href="$t('business.openbadge.pra7.link')" target="_blank" class="btn-sample nl-btn nl-btn-size--default nl-has-bg--sky-02 primary"><span>{{ $t('business.openbadge.pra7.btn') }}</span></a>
                        </div>

                        <div class="desc-box__str-2">
                            <a :href="$t('business.openbadge.pra7.link')" target="_blank" title="새창"><img :src="require(`@/assets/img/${$t('business.openbadge.pra7.image')}`)" /></a>
                        </div>
                    </div>
                <!-- // desc-box -->
                </div>
                <!-- // desc-box-container -->
            </div>
            <!-- // openbadge-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // openbadge-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-openbadge.png';
import TopVisualBusiness from '../components/TopVisualBusiness.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'OpenbadgePage',
    components: {
        TopVisualBusiness,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.business.openbadge.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.business.openbadge.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.business.openbadge.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.business.openbadge.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>