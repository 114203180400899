<template>
    <!-- inquery-page --> 
    <div class="inquery-page">
        <top-visual-contact></top-visual-contact>

        <sub-menu title="contact"></sub-menu>
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- nl-contant -->
            <div class="nl-contant">
                <h2 class="content-title center">
                    {{ $t('contactUs.pageTitle') }}
                </h2>

                <div class="nl-has-bg nl-has-bg--white">
                    <!-- contact-wrap -->
                    <div class="contact-wrap">
                        <div class="contact-top-text">
                            {{ $t('contactUs.text') }}<br class="m-hide" />
                            {{ $t('contactUs.text2') }}
                        </div>

                        <!-- contact-form-wrap -->
                        <div class="contact-form-wrap">
                            <!-- contact-form -->
                            <div class="contact-form">
                                <p class="text-required">{{ $t('contactUs.text3') }}</p>
                                
                                <!-- contact-form__row -->
                                <div class="contact-form__row">
                                    <div class="contact-form__col">
                                        <div class="contact-form__title">
                                            <span class="text">{{ $t('contactUs.text4') }}</span>
                                            <span class="required">*</span>
                                        </div>

                                        <div class="contact-form__content">
                                            <!-- nl-input -->
                                            <div class="nl-input">
                                                <div class="nl-input__control">
                                                    <div class="type-wrap">
                                                        <div class="nl-radio">
                                                            <input type="radio" id="type-1" name="type" :value="$t('contactUs.text5')" v-model="type" /> <label for="type-1" class="radio-label">{{ $t('contactUs.text5') }}</label>
                                                        </div>

                                                        <div class="nl-radio">
                                                            <input type="radio" id="type-2" name="type" :value="$t('contactUs.text6')" v-model="type" /> <label for="type-2" class="radio-label">{{ $t('contactUs.text6') }}</label>
                                                        </div>

                                                        <div class="nl-radio">
                                                            <input type="radio" id="type-3" name="type" :value="$t('contactUs.text7')" v-model="type" /> <label for="type-3" class="radio-label">{{ $t('contactUs.text7') }}</label>
                                                        </div>

                                                        <div class="nl-radio">
                                                            <input type="radio" id="type-4" name="type" :value="$t('contactUs.text8')" v-model="type" /> <label for="type-4" class="radio-label">{{ $t('contactUs.text8') }}</label>
                                                        </div>
                                                    </div>

                                                    <div class="error-text">
                                                        {{ validation.firstError('type') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- // nl-input -->
                                        </div>
                                    </div>
                                </div>
                                <!-- // contact-form__row -->

                                <!-- contact-form__row -->
                                <div class="contact-form__row row-2">
                                    <div class="contact-form__col">
                                        <div class="contact-form__title">
                                            <span class="text"><label for="user-name">{{ $t('contactUs.text9') }}</label></span>
                                            <span class="text-small">{{ $t('contactUs.text10') }}</span>
                                            <span class="required">*</span>
                                        </div>
                                        
                                        <div class="contact-form__content">
                                            <!-- nl-input -->
                                            <div class="nl-input">
                                                <div class="nl-input__control">
                                                    <div class="name-wrap">
                                                        <div class="input-effect-wrap" :class="{'input-effect-wrap--error': validation.hasError('name')}">
                                                            <div class="nl-text-field">
                                                                <input type="text" id="user-name" class="input-effect" v-model="name" maxlength="50" />

                                                                <span class="focus-border">
                                                                    <i></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="error-text">
                                                        {{ validation.firstError('name') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- // nl-input -->
                                        </div>
                                    </div>

                                    <div class="contact-form__col">
                                        <div class="contact-form__title">
                                            <span class="text">{{ $t('contactUs.text11') }}</span>
                                            <span class="text-small">{{ $t('contactUs.text12') }}</span>
                                            <span class="required">*</span>
                                        </div>

                                        <div class="contact-form__content">
                                            <!-- nl-input -->
                                            <div class="nl-input">
                                                <div class="nl-input__control">
                                                    <div class="name-wrap">
                                                        <div class="input-effect-wrap" :class="{'input-effect-wrap--error': validation.hasError('tel')}">
                                                            <div class="nl-text-field">
                                                                <input type="tel" class="input-effect" v-model="tel" maxlength="20" />

                                                                <span class="focus-border">
                                                                    <i></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="error-text">
                                                        {{ validation.firstError('tel') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- // nl-input -->
                                        </div>
                                    </div>
                                </div>
                                <!-- // contact-form__row -->

                                <!-- contact-form__row -->
                                <div class="contact-form__row row-3">
                                    <div class="contact-form__col">
                                        <div class="contact-form__title">
                                            <span class="text">{{ $t('contactUs.text13') }}</span>
                                            <span class="required">*</span>
                                        </div>

                                        <div class="contact-form__content">
                                            <div class="email-wrap">
                                                <!-- nl-input -->
                                                <div class="nl-input">
                                                    <div class="nl-input__control">
                                                        <div class="email-group">
                                                            <div class="input-effect-wrap" :class="{'input-effect-wrap--error': validation.hasError('email')}">
                                                                <div class="nl-text-field">
                                                                    <input type="email" class="input-effect" placeholder="contact@nlhdkorea.com" v-model="email" maxlength="50" />

                                                                    <span class="focus-border">
                                                                        <i></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="error-text">
                                                            {{ validation.firstError('email') }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- // nl-input -->                                                                              
                                            </div>
                                        </div>
                                    </div>

                                    <div class="contact-form__col">
                                        <div class="contact-form__title">
                                            <span class="text"><label for="path">{{ $t('contactUs.text14') }}</label></span>
                                            <span class="text-small">{{ $t('contactUs.text15') }}</span>
                                            <span class="text-small text-small--grey">{{ $t('contactUs.text16') }}</span>
                                        </div>

                                        <div class="contact-form__content">
                                            <div class="path-wrap">
                                                <!-- nl-input -->
                                                <div class="nl-input">
                                                    <div class="nl-input__control">
                                                        <div class="input-effect-wrap" :class="{'input-effect-wrap--error': validation.hasError('path')}">
                                                            <div class="nl-text-field nl-text-field--block">
                                                                <input type="text" id="path" class="input-effect" v-model="path" maxlength="50" />

                                                                <span class="focus-border">
                                                                    <i></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div class="error-text">
                                                            {{ validation.firstError('path') }}
                                                        </div>
                                                    </div>                                          
                                                </div>
                                            <!-- // nl-input -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- // contact-form__row -->

                                <!-- contact-form__row -->
                                <div class="contact-form__row">
                                    <div class="contact-form__col">
                                        <div class="contact-form__title">
                                            <span class="text"><label for="content">{{ $t('contactUs.text17') }}</label></span>
                                            <span class="text-small">{{ $t('contactUs.text18') }}</span>
                                            <span class="required">*</span>
                                        </div>

                                        <div class="contact-form__content">
                                            
                                            <!-- nl-input -->
                                            <div class="nl-input">
                                                <div class="nl-input__control">
                                                    <div class="content-wrap">
                                                        <div class="input-effect-wrap" :class="{'input-effect-wrap--error': validation.hasError('content')}">
                                                            <div class="nl-text-field nl-text-field--block">
                                                                <textarea id="content" class="input-effect" v-model="content" maxlength="5000"></textarea>

                                                                <span class="focus-border">
                                                                    <i></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="error-text">
                                                        {{ validation.firstError('content') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- // nl-input -->

                                            <!-- nl-input -->
                                            <div class="nl-input">
                                                <div class="nl-input__control">
                                                    <div class="agree-wrap">
                                                        <div class="nl-checkbox">
                                                            <input type="checkbox" id="agree" v-model="agree" />

                                                            <label for="agree" class="radio-label" v-html="$t('contactUs.text19')">
                                                                <!-- <a href="https://lecos.co.kr/kor/privacy" target="_blank" class="text-link">개인정보취급방침</a>에 동의합니다. -->
                                                            </label>
                                                        </div>

                                                        <span class="required">*</span>
                                                    </div>

                                                    <div class="error-text">
                                                        {{ validation.firstError('agree') }}
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- // nl-input -->
                                        </div>
                                    </div>
                                </div>
                            <!-- // contact-form__row -->
                            </div>
                        <!-- // contact-form -->
                        </div>
                        <!-- // contact-form-wrap -->

                        <!-- contact-btn-wrap -->
                        <div class="contact-btn-wrap">
                            <button
                                ref="btnInquery"
                                @click="submit"
                                type="button"
                                class="inquiry__btn nl-btn nl-btn-size--default nl-has-bg--sky-02 primary"
                            >
                                <span>{{ $t('contactUs.text20') }}</span>
                            </button>
                        </div>
                        <!-- // contact-btn-wrap -->
                    </div>
                    <!-- // contact-wrap -->
                </div>
            </div>
            <!-- // nl-contant -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // inquery-page -->
</template>

<script>
import { mapActions } from 'vuex';
import ogImage from '../assets/img/og-sns-inquery.png';
import TopVisualContact from '../components/TopVisualContact.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';
import SimpleVueValidation from 'simple-vue-validator'; // http://simple-vue-validator.magictek.cn/
const Validator = SimpleVueValidation.Validator;

export default {
    name: 'InqueryPage',
    components: {
        TopVisualContact,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.contact.inquery.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.contact.inquery.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.contact.inquery.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.contact.inquery.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            type: '',
            name: '',
            tel: '',
            email: '',
            path: '',
            content: '',
            agree: false,

            // type: '채용',
            // name: '1',
            // tel: '1',
            // email: 'mongnme@naver.com',
            // path: '1',
            // content: '1',
            // agree: true,
        };
    },

    validators: {
        type: function(value) {
            return Validator.value(value).required();
        },

        name: function(value) {
            return Validator.value(value).required().maxLength(50);
        },

        tel: function(value) {
            return Validator.value(value).required().digit().maxLength(20);
        },

        email: function(value) {
            return Validator.value(value).required().email().maxLength(50);
        },

        path: function(value) {
            return Validator.value(value).maxLength(50);
        },

        content: function(value) {
            return Validator.value(value).required().maxLength(5000);
        },

        agree: function(value) {
            // return Validator.value(value).custom(function() {
            //     if (!value) return '개인정보취급방침에 동의해야 합니다.';
            // });

            return Validator.value(value).custom(function() {
                let url = window.location.pathname;
                let urlSplit = url.split('/'); // "/" 로 전체 url 을 나눈다
                let lang = urlSplit[1];

                if (!value && lang == 'kor') {
                    return '개인정보취급방침에 동의해야 합니다.';
                } else if (!value && lang == 'eng') {
                    return 'You must agree to our privacy policy.';
                }
            });
        },
    },

    created() {
        //
    },

    mounted() {
        SimpleVueValidation.extendTemplates({
            required: this.$t('contactUs.validator.required'),
            maxLength: this.$t('contactUs.validator.maxLength'),
            digit: this.$t('contactUs.validator.digit'),
            email: this.$t('contactUs.validator.email')
        });
    },

    beforeDestroy() {
        // 
    },

    methods: {
        ...mapActions(
            'inquery', [ 'sendEmail' ]
        ),
    
        /* ============================================================
        * 문의하기 버튼 클릭
        * ============================================================ */
        submit: function () {
            this.$validate()
                .then((success) => {
                    if (success) {
                        console.log('btn', this.$refs['btnInquery']);

                        // this.$refs['btnInquery'].setAttribute('disabled', 'disabled');
                        this.$refs['btnInquery'].disabled = 'disabled';

                        // const lang = this.$route.params.lang;
                        let lang;
                        const { type, name, tel, email, path, content } = this;

                        if (this.$route.params.lang == 'kor') {
                            lang = 'ko-KR';
                        } else if (this.$route.params.lang == 'eng') {
                            lang = 'en';
                        } else {
                            lang = 'ko-KR';
                        }

                        console.log('>>>>>>>>>', { type, name, tel, email, path, content, lang });

                        this.sendEmail({ type, name, tel, email, path, content, lang })
                            .then(res => {
                                console.log('res', res);

                                if (res.data.result === 'Requested') {
                                    this.$router.push({ name: 'ContactUsFinishPage' });
                                }
                            }).catch(function (error) {
                                if (error.response) {
                                    // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                                    console.log(error.response.data);
                                    console.log(error.response.status);
                                    console.log(error.response.headers);
                                }
                                else if (error.request) {
                                    // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                                    // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
                                    // Node.js의 http.ClientRequest 인스턴스입니다.
                                    console.log(error.request);
                                }
                                else {
                                    // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                                    console.log('Error', error.message);
                                }
                                console.log(error.config);
                            }).finally(() => { 
                                console.log('end');
                                // this.$refs['btnInquery'].disabled = false;
                            });

                        // this.$modal.show(ModalBasic,
                        //     {
                        //         text: '문의해 주셔서 감사합니다.<br />빠른 시일 내에 답변 드리겠습니다.',
                        //         modal: this.$modal
                        //     },
                        //     {
                        //         name: 'modal-basic',
                        //         adaptive: true,
                        //         // clickToClose: false
                        //         // width : '330px',
                        //         // height : '130px',
                        //         // draggable: true,
                        //     },
                        //     {
                        //         // 'before-close': (e) => { e.cancel(); },
                        //     }
                        // );
                    }
                });
        },
    }
};
</script>