<template>
    <div class="sub-menu-continer">
        <ul class="sub-menu">
            <li class="sub-menu__item" v-for="(item, i) in subMenuItems[0][this.title]" :key="i">
                <router-link :to="{ name: item.routerUrl }" class="sub-menu__link">{{ item.name }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'SubMenu',

    props : [
        'title'
    ],

    data: function() {
        return {
            //
        };
    },

    computed: {
        subMenuItems() { // 서브메뉴 세팅값
            return [
                {
                    company: [
                        {
                            routerUrl: 'AboutPage',
                            name: this.$t('menu.company.sub.about')
                        },
                        {
                            routerUrl: 'HistoryPage',
                            name: this.$t('menu.company.sub.history')
                        },
                        {
                            routerUrl: 'GroupPage',
                            name: this.$t('menu.company.sub.group')
                        },
                        {
                            routerUrl: 'LocationPage',
                            name: this.$t('menu.company.sub.location')
                        }
                    ],

                    business: [
                        {
                            routerUrl: 'OpenbadgePage',
                            name: this.$t('menu.business.sub.openbadge')
                        },
                        {
                            routerUrl: 'MultiversePage',
                            name: this.$t('menu.business.sub.multiverse')
                        },
                        {
                            routerUrl: 'NetlivePage',
                            name: this.$t('menu.business.sub.netlive')
                        }
                    ],

                    news: [
                        {
                            routerUrl: 'PressListPage',
                            name: this.$t('menu.news.sub.press')
                        },
                        {
                            routerUrl: 'NewsListPage',
                            name: this.$t('menu.news.sub.news')
                        }                        
                    ],

                    contact: [
                        {
                            routerUrl: 'RecruitPage',
                            name: this.$t('menu.contact.sub.recruit')
                        },
                        {
                            routerUrl: 'InqueryPage',
                            name: this.$t('menu.contact.sub.inquery')
                        }
                    ],

                    privacy: [
                        {
                            routerUrl: 'PrivacyPage',
                            name: this.$t('menu.privacy.index')
                        }
                    ]
                }
            ];
        }
    },

    mounted() {    
        //
    },

    methods: {
        //
    }
};
</script>
