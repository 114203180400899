<template>
    <div class="top-visual top-visual--business">
        <div class="top-visual__text">
            <p class="text">Business</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopVisualCBusiness',

    mounted() {
        //
    },

    methods: {
        //
    }
};
</script>
