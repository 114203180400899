<template>
    <!-- group-page --> 
    <div class="group-page">
        <top-visual-company></top-visual-company>

        <sub-menu title="company"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- group-container -->
            <div class="group-container">
                <h2 class="content-title center">{{ $t('company.group.pageTitle') }}</h2>

                <h3 class="content-title-2">
                    <span class="content-title-2__text">{{ $t('company.group.pra.pageTitle') }}</span>
                </h3>

                <div class="group-info-container">
                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--1"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-1.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra.list.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra.list.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra.list.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--2"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-2.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra.list2.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra.list2.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra.list2.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--3"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-3.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra.list3.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra.list3.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra.list3.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--4"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-4.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra.list4.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra.list4.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra.list4.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--5"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-5.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra.list5.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra.list5.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra.list5.text2') }}</div>
                        </div>
                    </div>
                </div>

                <h3 class="content-title-2">
                    <span class="content-title-2__text">{{ $t('company.group.pra2.pageTitle') }}</span>
                </h3>

                <div class="group-info-container group-info-container-2">
                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--in-1"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-in-1.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra2.list.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra2.list.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra2.list.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--in-2"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-in-2.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra2.list2.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra2.list2.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra2.list2.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--in-3"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-in-3.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra2.list3.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra2.list3.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra2.list3.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--in-4"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-in-4.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra2.list4.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra2.list4.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra2.list4.text2') }}</div>
                        </div>
                    </div>

                    <div class="group-info">
                        <div class="group-info__logo group-info__logo--in-5"><object type="image/svg+xml" :data="require(`@/assets/svg/group-logo-in-5.svg`)" class="group-info__logo-svg">현재 브라우저는 iframe을 지원하지 않습니다.</object></div>
                        <div class="group-info__name">{{ $t('company.group.pra2.list5.title') }}</div>

                        <div class="group-info__desc">
                            <div class="group-info__title">{{ $t('company.group.pra2.list5.text') }}</div>
                            <div class="group-info__text">{{ $t('company.group.pra2.list5.text2') }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- // group-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // group-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-group.png';
import TopVisualCompany from '../components/TopVisualCompany.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'GroupPage',
    components: {
        TopVisualCompany,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.company.group.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.company.group.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.company.group.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.company.group.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>