<template>
    <div id="app" class="app" v-cloak>
        <div class="app-continer">
            <router-view name="header" />
            <router-view />
            <router-view name="inquiry" />
            <router-view name="footer" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'App',

    watch: {
        $route: {
            handler (to, from) {
                const body = document.getElementsByTagName('body')[0];

                if (from !== undefined) {
                    body.classList.remove('wrap-' + from.name.toLowerCase());
                }
                
                body.classList.add('wrap-' + to.name.toLowerCase());
            },
            immediate: true,
        }
    },
};
</script>

<style lang="scss">
    @import '../src/assets/scss/main.scss';
</style>
