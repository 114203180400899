<template>
    <div class="top-visual top-visual--news">
        <div class="top-visual__text">
            <p class="text">News</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopVisualNews',

    mounted() {
        //
    },

    methods: {
        //
    }
};
</script>
