<template>
    <!-- contact-us-page --> 
    <div class="contact-us-page">
        <top-visual-contact></top-visual-contact>

        <sub-menu title="contact"></sub-menu>
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- nl-contant -->
            <div class="nl-contant">
                <h2 class="content-title center">
                    {{ $t('contactUs.pageTitle') }}
                </h2>
                
                <div class="nl-has-bg nl-has-bg--white">
                    <!-- contact-wrap -->
                    <div class="contact-wrap">
                        <div class="contact-top-text">
                            {{ $t('contactUsFinish.text') }}<br />
                            {{ $t('contactUsFinish.text2') }}

                            <div class="contact-finish-btn-wrap">
                                <button
                                    @click="goPrevious"
                                    type="button"
                                    class="inquiry__btn nl-btn nl-btn-size--default nl-has-bg--sky-02 primary"
                                >
                                    <span>{{ $t('contactUsFinish.text3') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                <!-- // contact-wrap -->
                </div>
            </div>
            <!-- // nl-contant -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // contact-us-page -->
</template>

<script>
import TopVisualContact from '../components/TopVisualContact.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';
// import ogImage from '../assets/img/og-sns-contactUs.png';
// import ModalBasic  from '@/components/ModalBasic.vue'; // https://github.com/euvl/vue-js-modal // https://www.npmjs.com/package/vue-js-modal-gzonelee

export default {
    name: 'contactUsFinishPage',
    components: {        
        TopVisualContact,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.contact.inquery.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            // htmlAttrs: {
            //     lang: window.getApp.$i18n.locale // i18n 사용하지 않는 경우 직접 입력
            // },
            meta: [
                // { charset: 'utf-8'},

                // SEO setting
                // { name: 'description', content: this.$t('meta.contactUs.description'), vmid: 'description'},
                // { name: 'keywords', content: this.$t('meta.keywords') }, 
                // { name: 'author', content: this.$t('meta.author') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                // { property: 'og:title', content: this.$t('meta.contactUs.og.title'), vmid: 'og:title' },
                // { property: 'og:type', content: 'website', vmid: 'og:type' },
                // { property: 'og:image', content: ogImage, vmid: 'og:image' },
                // { property: 'og:description', content: this.$t('meta.contactUs.og.description'), vmid: 'og:description' },
                
                // mobile
                // { name: 'viewport', content: 'width=device-width, initial-scale=1'}
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 이전으로 버튼 클릭
        * ============================================================ */
        goPrevious: function () {
            // this.$router.go(-1);

            this.$router.push({
                name: 'InqueryPage',
                params: {lang: this.$route.params.lang}
            });
        }
    }
};
</script>