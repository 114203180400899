export default {
    getNews(state) {
        return state.news;
    },
    getNewsDetail(state) {
        return state.newsDetail;
    },
    getPress(state) {
        return state.press;
    }
};