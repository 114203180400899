<template>
    <!-- about-page --> 
    <div class="about-page">
        <top-visual-company></top-visual-company>

        <sub-menu title="company"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <div class="about-section-line about-section-space about-section-1">
                <h2 class="content-title center">{{ $t('company.about.pra') }}</h2>

                <div class="center about-logo"><img :src="require(`@/assets/img/about-img-01.png`)" /></div>

                <p class="about-text center" v-html="$t('company.about.pra12')"></p>
            </div>

            <div class="about-section-line about-section-space">
                <h2 class="content-title center">{{ $t('company.about.pra2') }}</h2>
                
                <div class="center about-principle-imgs">
                    <img :src="require(`@/assets/img/${$t('company.about.pra2ImgP')}`)" class="p" />
                    <img :src="require(`@/assets/img/${$t('company.about.pra2ImgT')}`)" class="t" />
                    <img :src="require(`@/assets/img/${$t('company.about.pra2ImgM')}`)" class="m" />
                </div>
            </div>

            <div class="about-section-space about-section-2">
                <h2 class="content-title center">{{ $t('company.about.pra3') }}</h2>
                
                <div class="about-standard">
                    <div class="about-standard__section">
                        <div class="about-standard__box">
                            <div class="about-standard__thumb"><img :src="require(`@/assets/img/about-img-standard-01.jpg`)" /></div>
                            <strong class="about-standard__title">{{ $t('company.about.pra31') }}</strong>
                            <p class="about-standard__text">{{ $t('company.about.pra31Text') }}</p>
                        </div>
                        
                        <div class="about-standard__box">
                            <div class="about-standard__thumb"><img :src="require(`@/assets/img/about-img-standard-02.jpg`)" /></div>
                            <strong class="about-standard__title">{{ $t('company.about.pra32') }}</strong>
                            <p class="about-standard__text">{{ $t('company.about.pra32Text') }}</p>
                        </div>
                    </div>

                    <div class="about-standard__section about-standard__section--full">
                        <div class="about-standard__box">
                            <div class="about-standard__thumb"><img :src="require(`@/assets/img/about-img-standard-03.jpg`)" /></div>
                            <strong class="about-standard__title">{{ $t('company.about.pra33') }}</strong>
                            <p class="about-standard__text">{{ $t('company.about.pra33Text') }}</p>
                        </div>

                        <div class="about-standard__box">
                            <div class="about-standard__thumb"><img :src="require(`@/assets/img/about-img-standard-04.jpg`)" /></div>
                            <strong class="about-standard__title">{{ $t('company.about.pra34') }}</strong>
                            <p class="about-standard__text">{{ $t('company.about.pra34Text') }}</p>
                        </div>

                        <div class="about-standard__box">
                            <div class="about-standard__thumb"><img :src="require(`@/assets/img/about-img-standard-05.jpg`)" /></div>
                            <strong class="about-standard__title">{{ $t('company.about.pra35') }}</strong>
                            <p class="about-standard__text">{{ $t('company.about.pra35Text') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // about-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-about.png';
import TopVisualCompany from '../components/TopVisualCompany.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'AboutPage',
    components: {
        TopVisualCompany,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.company.about.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.company.about.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.company.about.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.company.about.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>