<template>
    <!-- multiverse-page --> 
    <div class="multiverse-page">
        <top-visual-business></top-visual-business>

        <sub-menu title="business"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- multiverse-container -->
            <div class="multiverse-container">
                <h2 class="content-title center">
                    <span class="content-title__desc">{{ $t('business.multiverse.pageTitle') }}</span>
                    {{ $t('business.multiverse.pageTitleDes') }}
                </h2>

                <!-- desc-box-container -->
                <div class="desc-box-container">
                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.multiverse.pra.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.multiverse.pra.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.multiverse.pra.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.multiverse.pra2.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.multiverse.pra2.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.multiverse.pra2.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.multiverse.pra3.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.multiverse.pra3.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.multiverse.pra3.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.multiverse.pra4.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.multiverse.pra4.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.multiverse.pra4.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.multiverse.pra5.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.multiverse.pra5.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.multiverse.pra5.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->

                    <!-- desc-box -->
                    <div class="desc-box">
                        <div class="desc-box__str-1">
                            <div class="desc-box__title">{{ $t('business.multiverse.pra6.title') }}</div>

                            <div class="desc-box__text">
                                {{ $t('business.multiverse.pra6.text') }}
                            </div>
                        </div>

                        <div class="desc-box__str-2"><img :src="require(`@/assets/img/${$t('business.multiverse.pra6.image')}`)" /></div>
                    </div>
                    <!-- // desc-box -->                    
                </div>
                <!-- // desc-box-container -->

                <!-- desc-box -->
                <div class="des-box-2">
                    <div class="des-box-2__col">
                        <div class="des-box-2__svg">
                            <object type="image/svg+xml" :data="require(`@/assets/svg/multiverse-icon-1.svg`)">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                        </div>

                        <div class="des-box-2__title">
                            {{ $t('business.multiverse.pra7.list.title') }}
                        </div>

                        <div class="des-box-2__text" v-html="$t('business.multiverse.pra7.list.text')">
                        </div>
                    </div>

                    <div class="des-box-2__col">
                        <div class="des-box-2__svg">
                            <object type="image/svg+xml" :data="require(`@/assets/svg/multiverse-icon-2.svg`)">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                        </div>

                        <div class="des-box-2__title">
                            {{ $t('business.multiverse.pra7.list2.title') }}
                        </div>

                        <div class="des-box-2__text" v-html="$t('business.multiverse.pra7.list2.text')">
                        </div>
                    </div>

                    <div class="des-box-2__col">
                        <div class="des-box-2__svg">
                            <object type="image/svg+xml" :data="require(`@/assets/svg/multiverse-icon-3.svg`)">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                        </div>

                        <div class="des-box-2__title">
                            {{ $t('business.multiverse.pra7.list3.title') }}
                        </div>

                        <div class="des-box-2__text" v-html="$t('business.multiverse.pra7.list3.text')">
                        </div>
                    </div>
                </div>
                <!-- // desc-box -->

                <!-- major -->
                <!-- <div class="major">
                    <h2 class="content-title center">
                        Multiverse® 멀티버스 주요 도입사
                    </h2>

                    <div class="major-logs">
                        롤링 로고들 영역
                    </div>
                </div> -->
                <!-- // major -->
            </div>
            <!-- // multiverse-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // multiverse-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-multiverse.png';
import TopVisualBusiness from '../components/TopVisualBusiness.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'MultiversePage',
    components: {
        TopVisualBusiness,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.business.multiverse.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.business.multiverse.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.business.multiverse.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.business.multiverse.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>