<template>
    <!-- history-page --> 
    <div class="history-page">
        <top-visual-company></top-visual-company>

        <sub-menu title="company"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- history-container -->
            <div class="history-container">
                <h2 class="content-title center">{{ $t('company.history.pageTitle') }}</h2>

                <div class="history">
                    <dl class="history__section">
                        <dt class="history__year history__year--2022">{{ $t('company.history.2022.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2022.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2022.text2') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2022.text3') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2022.text4') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2022.text5') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2021">{{ $t('company.history.2021.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2021.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2021.text2') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2021.text3') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2021.text4') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2021.text5') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2020">{{ $t('company.history.2020.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2020.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2020.text2') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2020.text3') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2019">{{ $t('company.history.2019.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2019.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2019.text2') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2019.text3') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2019.text4') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2018">{{ $t('company.history.2018.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2018.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2018.text2') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2017">{{ $t('company.history.2017.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2017.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2017.text2') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2015">{{ $t('company.history.2015.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2015.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2015.text2') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2014">{{ $t('company.history.2014.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2014.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2014.text2') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2014.text3') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2014.text4') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2012">{{ $t('company.history.2012.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2012.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2011">{{ $t('company.history.2011.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2011.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2010">{{ $t('company.history.2010.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2010.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2010.text2') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2009">{{ $t('company.history.2009.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2009.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2008">{{ $t('company.history.2008.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2008.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2007">{{ $t('company.history.2007.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2007.text') }}</dd>
                        <dd class="history__des">{{ $t('company.history.2007.text2') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2006">{{ $t('company.history.2006.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2006.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2005">{{ $t('company.history.2005.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2005.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2003">{{ $t('company.history.2003.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2003.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--2002">{{ $t('company.history.2002.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.2002.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--1999">{{ $t('company.history.1999.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.1999.text') }}</dd>
                    </dl>

                    <dl class="history__section">
                        <dt class="history__year history__year--1998">{{ $t('company.history.1998.year') }}</dt>
                        <dd class="history__des">{{ $t('company.history.1998.text') }}</dd>
                    </dl>
                </div>
            </div>
            <!-- // history-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // history-page -->
</template>

<script>
import ogImage from '../assets/img/og-sns-history.png';
import TopVisualCompany from '../components/TopVisualCompany.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'HistoryPage',
    components: {
        TopVisualCompany,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.company.history.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.company.history.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.company.history.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.company.history.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>