<template>
    <div class="top-visual top-visual--contact">
        <div class="top-visual__text">
            <p class="text">Contact</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopVisualContact',

    mounted() {
        //
    },

    methods: {
        //
    }
};
</script>
