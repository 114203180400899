<template>
    <!-- news-view-page --> 
    <div class="news-view-page">
        <top-visual-news></top-visual-news>

        <sub-menu title="news"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- news-view-container -->
            <div class="news-view-container">
                <div class="board-view">
                    <div class="board-view__title">{{ getNewsDetail[id].title }}</div>
                    <div class="board-view__thumb"><img :src=getNewsDetail[id].thumb /></div>
                    <div class="board-view__content" v-html="getNewsDetail[id].content"></div>

                    <div class="board-view__button">
                        <router-link :to="{ name: 'NewsListPage' }">
                            <span>목록으로</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- // news-view-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // news-view-page -->
</template>

<script>
import { mapGetters } from 'vuex';
import ogImage from '../assets/img/og-sns-about.png';
import TopVisualNews from '../components/TopVisualNews.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'NewsViewPage',
    components: {
        TopVisualNews,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.news.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.news.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.news.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.news.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            id: null, // 해당되는 뉴스 데이터 id값
        };
    },

    computed: {
        ...mapGetters('news', ['getNewsDetail']),
    },

    created() {
        this.findNewsDetailId();
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 뉴스 상세 데이터 불러오기
        * ============================================================ */
        findNewsDetailId() {
            this.id = this.getNewsDetail.findIndex(v => v.id === this.$route.query.id);
        }
    }
};
</script>