<template>
    <!-- not-found-page --> 
    <div
        class="not-found-page"
        :style="{ height: 'calc(100vh - ' + footerHeight + 'px)' }"
        v-cloak
    >   
        <div class="text-wrap">
            <div class="icon icon-error">
                <i>
                    <object type="image/svg+xml" :data="require(`@/assets/svg/404-err.svg`)" class="error__svg"></object>
                </i>
            </div>

            <div class="text-deco">
                Sorry,<br />
                Page Not<br class="m-show" /> Found.
            </div>

            <router-link
                to="/"
                class="back__btn nl-btn nl-btn-size--default nl-has-bg--sky-02 primary"
            >
                <span>Back to home</span>
            </router-link>
        </div>
    </div>
    <!-- // not-found-page --> 
</template>

<script>
// import ogImage from '../assets/img/og-sns-home.png';

export default {
    name: 'NotFoundPage',
    components: {
        //
    },

    metaInfo() {
        return {
            title: 'Page Not Found',
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                // { name: 'description', content: this.$t('meta.company.about.description'), vmid: 'description'},
                // { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                // { property: 'og:title', content: this.$t('meta.company.about.og.title'), vmid: 'og:title' },
                // { property: 'og:type', content: 'website', vmid: 'og:type' },
                // { property: 'og:image', content: ogImage, vmid: 'og:image' },
                // { property: 'og:description', content: this.$t('meta.company.about.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            footerHeight: 0,
        };
    },

    created() {
        //
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.setFooterHeight();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        /* ============================================================
        * Footer UI 컨트롤
        * ============================================================ */
        handleResize() {
            this.setFooterHeight();
        },
        
        setFooterHeight() {
            this.footerHeight = document.querySelector('.footer').offsetHeight;
        }
    }
};
</script>