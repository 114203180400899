<template>
    <div id="header">
        <!-- header -->
        <header class="header" :class="[ activeModalSitemap ? 'header--fixed' : '' ]">
            <!-- nl-container -->
            <div class="nl-container">
                <!-- logo -->
                <h1 class="logo">
                    <router-link :to="{ name: 'HomePageSelectedLanguage' }">
                        <object type="image/svg+xml" :data="require(`@/assets/svg/logo-nlhd.svg`)" class="logo__svg">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                    </router-link>
                </h1>
                <!-- // logo -->

                <!-- tmenu- -->
                <div class="tmenu">
                    <ul class="nav">
                        <li 
                            v-for="(item, i) in navItems" 
                            :key="i"
                            class="nav__item"
                            @mouseover="openNav(i)"
                            @mouseleave="closeNav"
                        >
                            <div>
                                <router-link :to="item.url" @touchstart="toggleNav" class="nav__link">
                                    <span class="nav__content">{{ item.name }}</span>
                                </router-link>

                                <transition name="slide-fade">
                                    <div v-if="i == selectedNavNum" class="nav__subbar" :class="`nav__subbar--num${i}`">
                                        <ul class="nav__sitems">
                                            <li v-for="(item, i) in item.children" :key="i">
                                                <router-link :to="item.url" class="nav__slink">{{ item.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </transition>
                            </div>
                        </li>
                    </ul>

                    <button v-if="getScreenWidthNum < 1380 && activeModalSitemap" class="tsitemap" @click="closeModal">
                        <object type="image/svg+xml" :data="require(`@/assets/svg/icon-ham-close.svg`)" class="tsitemap__svg--close">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                    </button>

                    <button v-else class="tsitemap" @click="showModalSitemap">
                        <object type="image/svg+xml" :data="require(`@/assets/svg/icon-ham.svg`)" class="tsitemap__svg">현재 브라우저는 iframe을 지원하지 않습니다.</object>
                    </button>

                    <div class="tlang">
                        <!-- <router-link :to="to" v-if="this.$route.params.lang == 'eng'" @click.native="changeLang('kor')" class="tlang__button">
                            <span class="tlang__content">KOR</span>
                        </router-link>
                        
                        <router-link :to="to" v-if="this.$route.params.lang == 'kor'" @click.native="changeLang('eng')" class="tlang__button">
                            <span class="tlang__content">ENG</span>
                        </router-link> -->

                        <button v-if="this.$route.params.lang == 'eng'" @click="changeLang('kor')" class="tlang__button"><span class="tlang__content">KOR</span></button>
                        <button v-else-if="this.$route.params.lang == 'kor'" @click="changeLang('eng')" class="tlang__button"><span class="tlang__content">ENG</span></button>
                    </div>
                </div>
            <!-- // tmenu -->            
            </div>
            <!-- // nl-container -->            
        </header>
        <!-- // header -->

        <!-- modal-sitemap -->
        <modal-sitemap :status="activeModalSitemap" @closeModalSitemap="closeModal"></modal-sitemap>
        <!-- // modal-sitemap -->

        <!-- m-dim -->
        <div class="m-dim" v-if="activeModalDim" @click="closeModal"></div>
        <!-- // m-dim -->
    </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'; // https://github.com/duskload/mobile-device-detect
import ModalSitemap from './ModalSitemap.vue';

export default {
    name: 'Header',
    components: { ModalSitemap },

    data: function() {
        return {
            isMobile, // 모바일 여부 체크
            isOpenNavDrawer: false, // 서랍형 네비게이션 활성화
            isExpandedNav: false, // 대메뉴 오버시 서브바 활성화
            isChangeNavStyle: false, // 스크롤시 네비게이션 스타일 적용 활성화
            scrollPosition: 0,
            selectedNavNum: null, // 오버된 네비게이션 메뉴
            activeModalDim: false, // 모달의 dim 활성화
            activeModalSitemap: false, // 사이트맵 모달 활성화
            getScreenWidthNum: null, // 화면 넓이값
        
        };
    },

    computed: {
        navItems() { // 대메뉴 세팅값
            return [
                {
                    url: `/${this.$route.params.lang}/company`,
                    name: this.$t('menu.company.index'),
                    children: [
                        {
                            url: `/${this.$route.params.lang}/company/about`,
                            name: this.$t('menu.company.sub.about')
                        },
                        {
                            url: `/${this.$route.params.lang}/company/history`,
                            name: this.$t('menu.company.sub.history')
                        },
                        {
                            url: `/${this.$route.params.lang}/company/group`,
                            name: this.$t('menu.company.sub.group')
                        },
                        {
                            url: `/${this.$route.params.lang}/company/location`,
                            name: this.$t('menu.company.sub.location')
                        }
                    ]
                },
                {
                    url: `/${this.$route.params.lang}/business`,
                    name: this.$t('menu.business.index'),
                    children: [
                        {
                            url: `/${this.$route.params.lang}/business/openbadge`,
                            name: this.$t('menu.business.sub.openbadge')
                        },
                        {
                            url: `/${this.$route.params.lang}/business/multiverse`,
                            name: this.$t('menu.business.sub.multiverse')
                        },
                        {
                            url: `/${this.$route.params.lang}/business/netlive`,
                            name: this.$t('menu.business.sub.netlive')
                        }
                    ]
                },
                {
                    url: `/${this.$route.params.lang}/news`,
                    name: this.$t('menu.news.index'),
                    children: [
                        {
                            url: `/${this.$route.params.lang}/news/press`,
                            name: this.$t('menu.news.sub.press')
                        },
                        {
                            url: `/${this.$route.params.lang}/news/list`,
                            name: this.$t('menu.news.sub.news')
                        }
                    ]
                },
                {
                    url: `/${this.$route.params.lang}/contact`,
                    name: this.$t('menu.contact.index'),
                    children: [
                        {
                            url: `/${this.$route.params.lang}/contact/recruit`,
                            name: this.$t('menu.contact.sub.recruit')
                        },
                        {
                            url: `/${this.$route.params.lang}/contact/inquery`,
                            name: this.$t('menu.contact.sub.inquery')
                        }
                    ]
                },
            ];
        }
    },

    mounted() {
        this.initLang();
        this.getScreenWidth();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        /* ============================================================
        * 언어 설정
        * ============================================================ */
        initLang() {
            const urlLang = this.$route.params.lang;
            let i18nLang = '';
            let docLang = '';

            if (urlLang == 'kor') {
                i18nLang = 'ko';
                docLang = 'ko';
            } else if (urlLang == 'eng') {
                i18nLang = 'en';
                docLang = 'en';
            } else {
                i18nLang = 'ko';
                docLang = 'ko';
            }

            //i18n lang
            this.$i18n.locale = i18nLang;

            // doucment lang
            const html = document.documentElement;
            html.setAttribute('lang', docLang);
        },

        changeLang(value) {
            this.$router.push({
                name: this.$route.name,
                params: {lang: value}
            });

            this.$router.go();
        },

        /* ============================================================
        * window 이벤트
        * ============================================================ */
        handleScroll() {
            this.changeNavStyle();            
        },

        handleResize() {
            this.getScreenWidth(); // 화면 넓이값 구하기
        },

        /* ============================================================
        * 공통 이벤트
        * ============================================================ */
        getScreenWidth() {
            this.getScreenWidthNum = window.innerWidth;
        },

        /* ============================================================
        * 네비게이션 제어
        * ============================================================ */
        openNav: function(i) {
            this.selectedNavNum = i; // 오버된(선택된) 메뉴

            if (!('ontouchstart' in document.documentElement)) {
                this.isExpandedNav = true;
            }
        },

        closeNav: function() {
            this.selectedNavNum = null;
            this.isExpandedNav = false;
        },

        toggleNav: function() {
            this.isExpandedNav = !this.isExpandedNav;
        },

        changeNavStyle() {
            this.scrollPosition = window.pageYOffset;

            if (this.scrollPosition > 0) {
                this.isChangeNavStyle = true;
            } else {
                this.isChangeNavStyle = false;
            }
        },        

        /* ============================================================
        * 상단 사이트맵 버튼 모달
        * ============================================================ */
        showModalSitemap() {
            this.activeModalDim = true;
            this.activeModalSitemap = true;
        },

        closeModal() {
            this.activeModalDim = false;
            this.activeModalSitemap = false;
        },        
    }
};
</script>
