<template>
    <!-- privacy-page --> 
    <div class="privacy-page">
        <!-- nl-content -->
        <div class="nl-content">
            <!-- terms -->
            <div class="terms">
                <div class="t-title-area"><div class="terms__title-1">{{ $t('privacy.pageTitle') }}</div></div>

                <div class="terms__pra">
                    {{ $t('privacy.text') }}<br />
                    {{ $t('privacy.text2') }}
                </div>

                <div class="terms__division first">
                    <div class="terms__title-3"><span class="terms__title-3__space">{{ $t('privacy.pra.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra.titleText') }}</span></div>

                    <div class="terms__con">
                        {{ $t('privacy.pra.text') }}<br />
                        {{ $t('privacy.pra.text2') }}<br />
                        {{ $t('privacy.pra.text3') }}
                    </div>
                </div>

                <div class="terms__division">
                    <div class="terms__title-3"><span class="terms__title-3__space">{{ $t('privacy.pra2.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra2.titleText') }}</span></div>

                    <div class="terms__con">
                        {{ $t('privacy.pra2.text') }}<br />
                        {{ $t('privacy.pra2.text2') }}<br />
                        {{ $t('privacy.pra2.text3') }}
                    </div>
                </div>

                <div class="terms__division">
                    <div class="terms__title-3"><span class="terms__title-3__space">{{ $t('privacy.pra3.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra3.titleText') }}</span></div>

                    <div class="terms__con">
                        {{ $t('privacy.pra3.text') }}
                    </div>
                </div>

                <div class="terms__division">
                    <div class="terms__title-3"><span class="terms__title-3__space">{{ $t('privacy.pra4.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra4.titleText') }}</span></div>

                    <div class="terms__con">
                        {{ $t('privacy.pra4.text') }}
                    </div>
                </div>

                <div class="terms__division">
                    <div class="terms__title-3"><span class="terms__title-3__space">{{ $t('privacy.pra5.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra5.titleText') }}</span></div>

                    <div class="terms__con">
                        {{ $t('privacy.pra5.text') }}
                    </div>
                </div>
            </div>
            <!-- //terms -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // privacy-page -->
</template>

<script>
// import ogImage from '../assets/img/og-sns-privacy.png';
// import TopVisualPrivacy from '../components/TopVisualPrivacy.vue';
// import SubMenu from '../components/SubMenu.vue';
// import PageDown from '../components/PageDown.vue';

export default {
    name: 'PrivacyPage',
    components: {
        //
    },

    metaInfo() {
        return {
            title: this.$t('meta.privacy.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                // { name: 'description', content: this.$t('meta.privacy.description'), vmid: 'description'},
                // { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                // { property: 'og:title', content: this.$t('meta.privacy.og.title'), vmid: 'og:title' },
                // { property: 'og:type', content: 'website', vmid: 'og:type' },
                // { property: 'og:image', content: ogImage, vmid: 'og:image' },
                // { property: 'og:description', content: this.$t('meta.privacy.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>