<template>
    <!-- news-list-page --> 
    <div class="news-list-page">
        <top-visual-news></top-visual-news>

        <sub-menu title="news"></sub-menu>    
            
        <page-down></page-down>

        <!-- nl-content -->
        <div class="nl-content">
            <!-- news-list-container -->
            <div class="news-list-container">
                <h2 class="content-title center">
                    네트러닝 홀딩스의 새로운 소식을 확인하세요
                </h2>

                <div class="board-list-container">
                    <ul class="board-list">
                        <li v-for="item in getNews" :key="item.id" class="board-list__item">
                            <div class="board-list__thumb"><img :src=item.thumb /></div>

                            <div class="board-list__col">
                                <div class="board-list__title">{{ item.title }}</div>
                                <div class="board-list__content">{{ item.content }}</div>
                    
                                <div class="board-list__button">
                                    <router-link :to="{ name: 'NewsViewPage', query: { id: item.id } }">
                                        <span>자세히 보기</span>
                                    </router-link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- // news-list-container -->
        </div>
        <!-- // nl-content -->
    </div>
    <!-- // news-list-page -->
</template>

<script>
import { mapGetters } from 'vuex';
import ogImage from '../assets/img/og-sns-news.png';
import TopVisualNews from '../components/TopVisualNews.vue';
import SubMenu from '../components/SubMenu.vue';
import PageDown from '../components/PageDown.vue';

export default {
    name: 'NewsListPage',
    components: {
        TopVisualNews,
        SubMenu,
        PageDown
    },

    metaInfo() {
        return {
            title: this.$t('meta.news.title'),
            titleTemplate: `%s ${this.$t('meta.titleTemplate')}`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름)
            meta: [
                // SEO setting
                { name: 'description', content: this.$t('meta.news.description'), vmid: 'description'},
                { name: 'keywords', content: this.$t('meta.keywords') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                { property: 'og:title', content: this.$t('meta.news.og.title'), vmid: 'og:title' },
                { property: 'og:type', content: 'website', vmid: 'og:type' },
                { property: 'og:image', content: ogImage, vmid: 'og:image' },
                { property: 'og:description', content: this.$t('meta.news.og.description'), vmid: 'og:description' }
            ]
        };
    },
    
    data: function() {
        return {
            //
        };
    },

    computed: {
        ...mapGetters('news', ['getNews']),
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        // 
    },

    methods: {
        /* ============================================================
        * 주석
        * ============================================================ */
        //
    }
};
</script>