import Vue from 'vue';
import VueRouter from 'vue-router';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomePage from '../views/HomePage';
import AboutPage from '../views/AboutPage';
import HistoryPage from '../views/HistoryPage';
import GroupPage from '../views/GroupPage';
import LocationPage from '../views/LocationPage';
import OpenbadgePage from '../views/OpenbadgePage';
import MultiversePage from '../views/MultiversePage';
import NetlivePage from '../views/NetlivePage';
import NewsListPage from '../views/NewsListPage';
import NewsViewPage from '../views/NewsViewPage';
import PressListPage from '../views/PressListPage';
import RecruitPage from '../views/RecruitPage';
import InqueryPage from '../views/InqueryPage';
import ContactUsFinishPage from '../views/ContactUsFinishPage';
import PrivacyPage from '../views/PrivacyPage';
import NotFoundPage from '../views/NotFoundPage';
import Inquiry from '../components/Inquiry';

/*
    home
    company
        about (회사 소개)
        history (주요 연혁)
        group (그룹사 및 소속단체)
        location (오시는 길)
    business
        openbadge (오픈 배지)
        multiverse (멀티버스)
        netlive (넷라이브)
    news
        list
        view1
        view2
    contact
        recruit (인재채용)
        inquery (문의하기)
    privacy (개인정보취급방침)
*/

Vue.use(VueRouter);

const routes = [    
    {
        path: '/',
        name: 'HomePage',
        components: {
            default: HomePage,
            footer: Footer
        },
    },
    {
        path: '/:lang',
        name: 'HomePageSelectedLanguage',
        components: {
            default: HomePage,
            footer: Footer
        },
    },
    {
        path: '/:lang/company',
        name: 'CompanyPage',
        components: {
            header: Header,
            default: AboutPage,
            footer: Footer
        },
        redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/:lang/company/about' };
        },
    },
    {
        path: '/:lang/company/about',
        name: 'AboutPage',
        components: {
            header: Header,
            default: AboutPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/company/history',
        name: 'HistoryPage',
        components: {
            header: Header,
            default: HistoryPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/company/group',
        name: 'GroupPage',
        components: {
            header: Header,
            default: GroupPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/company/location',
        name: 'LocationPage',
        components: {
            header: Header,
            default: LocationPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/business',
        name: 'BusinessPage',
        components: {
            header: Header,
            default: OpenbadgePage,
            footer: Footer
        },
        redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/:lang/business/openbadge' };
        },
    },
    {
        path: '/:lang/business/openbadge',
        name: 'OpenbadgePage',
        components: {
            header: Header,
            default: OpenbadgePage,
            inquiry: Inquiry,
            footer: Footer
        },
    },
    {
        path: '/:lang/business/multiverse',
        name: 'MultiversePage',
        components: {
            header: Header,
            default: MultiversePage,
            footer: Footer
        },
    },
    {
        path: '/:lang/business/netlive',
        name: 'NetlivePage',
        components: {
            header: Header,
            default: NetlivePage,
            footer: Footer
        },
    },
    {
        path: '/:lang/news',
        name: 'NewsPage',
        components: {
            header: Header,
            default: NewsListPage,
            footer: Footer
        },
        redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/:lang/news/press' };
        },
    },
    {
        path: '/:lang/news/list',
        name: 'NewsListPage',
        components: {
            header: Header,
            default: NewsListPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/news/view',
        name: 'NewsViewPage',
        components: {
            header: Header,
            default: NewsViewPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/news/press',
        name: 'PressListPage',
        components: {
            header: Header,
            default: PressListPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/contact',
        name: 'ContactPage',
        components: {
            header: Header,
            default: RecruitPage,
            footer: Footer
        },
        redirect: () => {
            // the function receives the target route as the argument
            // we return a redirect path/location here.
            return { path: '/:lang/contact/recruit' };
        },
    },
    {
        path: '/:lang/contact/recruit',
        name: 'RecruitPage',
        components: {
            header: Header,
            default: RecruitPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/contact/inquery',
        name: 'InqueryPage',
        components: {
            header: Header,
            default: InqueryPage,
            footer: Footer
        },
    },
    {
        path: '/:lang/contact-us-finish',
        name: 'ContactUsFinishPage',
        // component: UserForOpenBadgePage,
        components: {
            header: Header,
            default: ContactUsFinishPage,
            // default: () => import(/* webpackChunkName: "UserForOpenBadgePage" */ '../views/UserForOpenBadgePage.vue'),
            // inquiry: Inquiry,
            footer: Footer
        },
        // props: {
        //     default: true
        // }
    },
    {
        path: '/:lang/privacy',
        name: 'PrivacyPage',
        components: {
            header: Header,
            default: PrivacyPage,
            footer: Footer
        },
    },
    {
        path: '*',
        name: 'NotFoundPage',
        components: {
            default: NotFoundPage,
            footer: Footer
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() { 
        return { x: 0, y: 0 }; 
    },
    routes,
});

router.beforeEach((to, from, next) => {
    const defaultLang = 'kor';
    const registedLang = ['kor', 'eng'];

    if ( to.name === 'HomePage') {
        // console.log('>>>>>>>> 3');
        next(`/${defaultLang}`);
    } else if ( to.name === 'NotFoundPage' ) {
        // console.log('>>>>>>>> 4');
        next();
    } else {
        if ( registedLang.find(l => l === to.params.lang) ) {
            // console.log('>>>>>>>> 1');
            next();
        } else {
            // console.log('>>>>>>>> 2');
            next(`${to.params.lang}/404`);
        }
    }
});

export default router;
