import Vue from 'vue';
import Vuex from 'vuex';

import inquery from './inquery';
import news from './news';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        inquery,
        news
    }
});
